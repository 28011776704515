'use client'

import { User } from '@prisma/client'
import { useSession } from 'next-auth/react'
import { Fragment, memo, useContext, useState } from 'react'
import { fetcher } from 'src/app/lib/fetcher'
import { handleExceptionRequest } from 'src/app/lib/utils/toast.util'
import { UserSettingsContext } from 'src/app/providers/UserSettingsProvider'
import { Icon } from 'src/components/common/Icon'
import { EnMethod } from 'src/enums'
import { EnUserSetting } from 'src/enums/user-setting.enum'
import { t } from 'src/helpers/translate.helper'

export const AdultModal = memo(function AdultModal(props: {
  onClose: () => void
}) {
  const { changeUserSetting } = useContext(UserSettingsContext)
  const [isFetching, setIsFetching] = useState(false)
  const { data: session, update } = useSession()

  const onClick = (isAdult: boolean) => {
    if (!session || !isAdult) {
      changeUserSetting(EnUserSetting.IS_ADULT, isAdult)
      return props.onClose()
    }

    fetcher<User>({
      url: `/users/${session.user.id}`,
      params: {
        method: EnMethod.PATCH,
        body: JSON.stringify({
          isAdult,
        }),
      },
    })
      .then(async () => {
        changeUserSetting(EnUserSetting.IS_ADULT, isAdult)
        await update({
          ...session,
          user: {
            ...session.user,
            isAdult,
          },
        })

        props.onClose()
      })
      .catch(handleExceptionRequest)
      .finally(() => setIsFetching(false))
  }

  return (
    <Fragment>
      <div className="mt-1 text-left text-secondary">
        {t(
          "This site contents content with don't available for people whose age less then 18 ages",
        )}
      </div>

      <div className="grid grid-cols-2 gap-x-2 mt-6">
        <button
          onClick={() => onClick(false)}
          disabled={isFetching}
          className="rounded-md ring-inset disabled:cursor-not-allowed dark:text-red-500 text-red-600 dark:hover:text-black-1000 hover:text-white hover:bg-red-600 dark:hover:bg-red-500 dark:ring-red-500 ring-red-600 ring-1 outline-none"
        >
          {t("I don't have 18 ages")}
        </button>
        <button
          disabled={isFetching}
          onClick={() => {
            setIsFetching(true)
            onClick(true)
          }}
          className="rounded-md grid grid-flow-col justify-center gap-x-2 bg-primary disabled:cursor-not-allowed font-medium px-3 py-2 text-sm text-white dark:text-black-1000"
        >
          {isFetching && <Icon name="loader" className="text-inherit w-4" />}
          {t('I have 18 ages')}
        </button>
      </div>
    </Fragment>
  )
})

export default AdultModal
