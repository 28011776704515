'use client'

import Link from 'next/link'
import { Fragment, memo } from 'react'
import { t } from 'src/helpers/translate.helper'
import { PaymentFobbidenBody } from '../../common/Modal/PaymentFobbidenBody'

export const SubscriptionModal = memo(function SubscriptionModal(props: {
  onClose: () => void
}) {
  return (
    <Fragment>
      <h3 className="text-lg font-medium leading-6">
        {t('Purchase subscription')}
      </h3>

      <PaymentFobbidenBody
        onClose={props.onClose}
        textBlock={
          <Fragment>
            {t('You can buy subscription only on site ')}
            <Link
              className="text-primary font-medium"
              href="https://xn--80aaalhzvfe9b4a.xn--80asehdb/"
            >
              хентайманга.онлайн
            </Link>
            . {t('This subscription will be work for both sites')}
          </Fragment>
        }
      />
    </Fragment>
  )
})

export default SubscriptionModal
