'use client'

import clsx from 'clsx'
import { Session } from 'next-auth'
import { useParams, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { fetcher } from 'src/app/lib/fetcher'
import { handleExceptionRequest } from 'src/app/lib/utils/toast.util'
import { EnMethod } from 'src/enums'
import { getAdminSocket } from 'src/instances/admin-socket'
import Header from '../Header'
import { BookWrapper } from './BookWrapper'

export const HeaderWrapper = (props: {
  className?: string
  session: Session | null
}) => {
  const params = useParams()
  const router = useRouter()

  const [notifications, setNotifications] = useState({
    hasImportant: false,
    count: 0,
  })

  useEffect(() => {
    if (!props.session) {
      return
    }

    fetcher<{ count: number; hasImportant: boolean }>({
      url: `/notifications/count`,
      params: {
        method: EnMethod.GET,
      },
    })
      .then((data) => {
        setNotifications(data)
      })
      .catch(handleExceptionRequest)
  }, [props.session])

  useEffect(() => {
    if (!props.session) {
      return
    }

    const socket = getAdminSocket(props.session?.user.accessToken)
    if (!socket) {
      return
    }

    socket.on('frontend.read-notification', (data: { count: number }) => {
      setNotifications((notifications) => ({
        ...notifications,
        count: notifications.count - data.count,
      }))
    })

    socket.on('frontend.add-notification', (data: { isImportant: boolean }) => {
      setNotifications((notifications) => ({
        hasImportant: data.isImportant ?? notifications.hasImportant,
        count: notifications.count + 1,
      }))

      if (window.location.pathname === '/user/notifications') {
        router.push(`/user/notifications/?refreshId=${new Date().getTime()}`)
      }
    })
  }, [props.session, router])

  if (params?.bookSlug !== undefined && params?.id !== undefined) {
    return null
  }

  if (params?.bookSlug !== undefined) {
    return (
      <BookWrapper className={props.className}>
        <Header session={props.session} notifications={notifications} />
      </BookWrapper>
    )
  }

  return (
    <header className={clsx('shadow dark:shadow-black-1000', props.className)}>
      <Header session={props.session} notifications={notifications} />
    </header>
  )
}
