import { fetcher } from 'src/app/lib/fetcher'
import { EnMethod } from 'src/enums'

export const checkEmailExist = async (
  resolve: (result: boolean) => void,
  email?: string,
) => {
  if (!email) {
    return resolve(false)
  }

  fetcher<{ isUnique: boolean }>({
    url: '/users/check-email',
    params: {
      method: EnMethod.POST,
      body: JSON.stringify({
        email,
      }),
    },
  })
    .then((data) => {
      resolve(data.isUnique)
    })
    .catch((error) => {
      resolve(false)
    })
}
