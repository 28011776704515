'use client'

import clsx from 'clsx'
import React, { memo, useEffect, useState } from 'react'

export const BookWrapper = memo(function BookHeaderWrapper(props: {
  children: React.ReactNode
  className?: string
}) {
  const [display, setDisplay] = useState(false)

  useEffect(() => {
    setDisplay(window.scrollY !== 0)
  }, [])

  useEffect(() => {
    const handleScrollBook = () => {
      if (window.scrollY === 0 && display) {
        return setDisplay(false)
      }

      if (window.scrollY > 0 && !display) {
        return setDisplay(true)
      }
    }

    window.addEventListener('scroll', handleScrollBook)
    return () => {
      window.removeEventListener('scroll', handleScrollBook)
    }
  }, [display])

  return (
    <header
      className={clsx(
        'max-md:fixed translate-y-0 md:bg-white md:dark:bg-black-700 grid z-20 dark:text-gray-150 h-12 top-0 transition-all duration-[225ms] inset-x-0 sticky',
        {
          'bg-white dark:bg-black-700 shadow dark:shadow-black-1000': display,
        },
      )}
    >
      {props.children}
    </header>
  )
})
