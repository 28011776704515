import { Fragment, memo } from 'react'
import { t } from 'src/helpers/translate.helper'

export const PaymentFobbidenBody = memo(function PaymentFobbidenBody(props: {
  textBlock: React.ReactNode
  onClose: () => void
}) {
  return (
    <Fragment>
      <div className="mt-4 text-left">{props.textBlock}</div>

      <div className="grid grid-cols-2 gap-x-2 mt-6">
        <button
          onClick={() => props.onClose()}
          className="rounded-md ring-inset dark:text-red-500 text-red-600 dark:hover:text-black-1000 hover:text-white hover:bg-red-600 dark:hover:bg-red-500 dark:ring-red-500 ring-red-600 ring-1 outline-none"
        >
          {t('Close')}
        </button>

        <button
          onClick={() =>
            (window.location.href = 'https://xn--80aaalhzvfe9b4a.xn--80asehdb')
          }
          className="rounded-md bg-primary font-medium px-3 py-2 text-sm text-white dark:text-black-1000"
        >
          {t('Proceed')}
        </button>
      </div>
    </Fragment>
  )
})
