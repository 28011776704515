'use client'

import clsx from 'clsx'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { Fragment, memo, useContext, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { fetcher } from 'src/app/lib/fetcher'
import { isPaymentFobbiden } from 'src/app/lib/utils/main.util'
import { addToast, handleExceptionRequest } from 'src/app/lib/utils/toast.util'
import { ModalContext } from 'src/components/providers/ModalProvider'
import { CHAPTER_COST } from 'src/config'
import { EnMethod, EnModal, EnToastType } from 'src/enums'
import { useYupValidationResolver } from 'src/helpers/client/client.helper'
import { getDepositBonus } from 'src/helpers/payment.helper'
import { t } from 'src/helpers/translate.helper'
import { useLogin } from 'src/hooks'
import yup from 'src/instances/yup'
import { Button } from '../../common/Form/Button'
import { Input } from '../../common/Form/Input'
import { PaymentFobbidenBody } from '../../common/Modal/PaymentFobbidenBody'

export type FormType = {
  sum: string
}

const buttons = [
  { sum: 250, bonus: 15 },
  { sum: 500, bonus: 40 },
  { sum: 750, bonus: 65 },
  { sum: 1000, bonus: 100 },
]

const schema = yup.object().shape({
  sum: yup.string().required(),
})

export const DepositModal = memo(function DepositModal(props: {
  onClose: () => void
}) {
  const { displayLoginPopup } = useLogin()
  const { toggleModal } = useContext(ModalContext)
  const { data: session } = useSession()

  const resolver = useYupValidationResolver(schema)
  const {
    control,
    handleSubmit,
    trigger,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormType>({
    resolver,
    mode: 'all',

    defaultValues: {
      sum: '',
    },
  })

  const sum = watch('sum')
  const fullSum = +sum + getDepositBonus(+sum)

  useEffect(() => {
    document.getElementById('sum')?.focus()
  }, [])

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    const balance = +data.sum

    if (balance < CHAPTER_COST)
      return setError('sum', { message: t('Min {sum}', { sum: CHAPTER_COST }) })

    if (!session) return displayLoginPopup()
    if (!session.user.email) return toggleModal(EnModal.EMAIL)

    return fetcher<{ paymentLink: string | null }>({
      url: '/payments/deposit',
      params: {
        method: EnMethod.POST,
        body: JSON.stringify({
          sum: data.sum,
        }),
      },
    })
      .then((data) => {
        if (!data.paymentLink) {
          return addToast(t('Error is happend'), EnToastType.DANGER)
        }

        if (process.env.NEXT_PUBLIC_ENV_APP_NAME !== 'MangaOnline') {
          window.location.href =
            'https://xn--80aai8ag.xn--80asehdb/redirect?redirectUrl=' +
            encodeURIComponent(data.paymentLink)
          return
        }

        window.location.href = data.paymentLink
      })
      .catch(handleExceptionRequest)
  }

  if (isPaymentFobbiden()) {
    return (
      <PaymentFobbidenBody
        onClose={props.onClose}
        textBlock={
          <Fragment>
            {t('You can deposit on site')}
            <Link
              className="text-primary font-medium"
              href="https://xn--80aaalhzvfe9b4a.xn--80asehdb/"
            >
              хентайманга.онлайн
            </Link>
            . {t('Deposit will be common for two sites')}.
          </Fragment>
        }
      />
    )
  }

  return (
    <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-y-1">
        <Input<FormType>
          control={control}
          type="number"
          placeholder={t('How mush deposit?')}
          name="sum"
        />
      </div>

      <div className="grid gap-2 md:gap-3 text-white grid-cols-2 mt-2">
        {buttons.map((button, key) => (
          <div
            onClick={() => {
              setValue('sum', String(button.sum))
              trigger()
            }}
            key={key}
            className="grid cursor-pointer transition active:scale-90 active:opacity-75 px-3 py-3 bg-[#7C3AED] rounded-md"
          >
            <span>{button.sum} ₽</span>
            <span>
              + {t('bonus')} {button.bonus} ₽
            </span>
          </div>
        ))}
      </div>

      <p
        className={clsx('py-4 text-center', {
          invisible: parseInt(sum, 10) === 0,
        })}
      >
        {t('Balance will refill to {sum}', { sum: fullSum })}
      </p>

      <div className="grid grid-flow-col gap-x-2 gap-3">
        <Button
          withLabel={false}
          title={t('Pay {sum}', { sum })}
          className="mt-2 w-full justify-center"
          isSubmitting={isSubmitting}
          errors={errors}
        />
      </div>
    </form>
  )
})

export default DepositModal
