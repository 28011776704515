'use client'

import { EnCommentComplainReason, User } from '@prisma/client'
import { memo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { fetcher } from 'src/app/lib/fetcher'
import { addToast, handleExceptionRequest } from 'src/app/lib/utils/toast.util'
import { EnMethod, EnToastType } from 'src/enums'

import { CommentComplainReasonEnum } from 'src/enums/comment-complain-reason.enum'
import { useYupValidationResolver } from 'src/helpers/client/client.helper'
import { sleep } from 'src/helpers/main.helper'
import { t } from 'src/helpers/translate.helper'
import yup from 'src/instances/yup'
import { Button } from '../../common/Form/Button'
import { Select } from '../../common/Form/Select'
import { Textarea } from '../../common/Form/Textarea'

export type FormType = {
  reason: EnCommentComplainReason
  message: string | undefined
  commentId: number
}

const requiredReasons: EnCommentComplainReason[] = [
  EnCommentComplainReason.ANOTHER,
]

const schema = yup.object().shape({
  commentId: yup.number(),
  reason: yup
    .mixed()
    .oneOf(Object.keys(EnCommentComplainReason).map((value) => value))
    .required(),
  message: yup
    .string()
    .nullable()
    .when(['reason'], {
      is: (reason: EnCommentComplainReason) => requiredReasons.includes(reason),
      then: (schema) => schema.required(),
    }),
})

export const CommentComplainModal = memo(function ComplainModal(props: {
  onClose: () => void
  commentId: number
}) {
  const resolver = useYupValidationResolver(schema)
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<FormType>({
    mode: 'all',
    resolver,

    defaultValues: {
      reason: undefined,
      message: undefined,
      commentId: props.commentId,
    },
  })

  const validateFields = async () => {
    await sleep(200)
    trigger()
  }

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    return fetcher<User>({
      url: '/comments-complains',
      params: {
        method: EnMethod.POST,
        body: JSON.stringify(data),
      },
    })
      .then(async () => {
        addToast(t('Complain successful send'), EnToastType.SUCCESS)
        props.onClose()
      })
      .catch(handleExceptionRequest)
  }

  return (
    <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-y-1">
        <Select<FormType>
          control={control}
          name="reason"
          loadOptions={() =>
            Promise.resolve(
              (
                Object.keys(
                  EnCommentComplainReason,
                ) as EnCommentComplainReason[]
              ).map((reason, key) => ({
                label: t(CommentComplainReasonEnum.getLabels()[reason]),
                value: reason,
                key,
              })),
            )
          }
          onChange={() => {
            validateFields()
          }}
          placeholder={t('select.reason')}
          title={t('complain.reason')}
        />

        <Textarea<FormType>
          placeholder={t('Detail information about compain')}
          control={control}
          name="message"
          title={t('complain.comment')}
        />
      </div>

      <div className="grid grid-flow-col gap-x-2 gap-3 justify-start">
        <Button
          title={t('Send')}
          className="mt-2"
          isSubmitting={isSubmitting}
          errors={errors}
        />
      </div>
    </form>
  )
})

export default CommentComplainModal
